.about {
    min-height: 100vh;
    background-color: white;
}

.about h1 {
    text-align: center;
    font-size: 3rem;
}

.about img {
    border-radius: 50%;
    border: 8px solid var(--darkBlue);
    justify-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.about h2 {
    text-align: center;
}

.about h4 { 
    width: 60%;
    margin: 40px auto 5rem;
    text-align: center;
    font-weight: 300;
    line-height: 1.5;
}

@media screen and (max-width: 600px) {
    .about h4 {
        width: 90%;
        text-align: start;
        font-size: 1.25rem;
    }
}
/* Header */ 
.custom-jumbo {
    background-color: var(--dark); 
    color: var(--light);
    border-radius: 0;
    border-bottom: 1px solid var(--dark);
    background-image: url("../../images/tech-background.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    padding-top: 30vh;
}

.header-div {
    width: fit-content;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
}

.custom-jumbo h1 {
    text-align: center;
    color: white;
    /* background-color:#6DA6E3; */
    background-color: var(--darkBlue);
    font-size: 5.5rem;
    padding: 0 15px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    opacity: .75;
    backdrop-filter: blur(6px);
}

.custom-jumbo h2 {
    text-align: center;
    color: white;
    /* background-color: var(--purple); */
    /* background-color:#6DA6E3; */
    background-color: var(--darkBlue);
    font-family: 'Courier New', Courier, monospace;
    opacity: .75;
    backdrop-filter: blur(6px);
}

@media screen and (max-width: 600px) {
    .custom-jumbo {
        /* background-image: url("../../images/tech-city-background.jpg"); */
        background-attachment: initial;
        background-position: center;
    }

    .custom-jumbo h1 {
        font-size: 3.5rem;
    }

    .custom-jumbo h2 {
        font-size: 1.75rem;
    }
}
:root {
  --purple: rgb(98, 2, 207);
  --blue: #7da4ff;
  --grayBlue: #9FADCC;
  --darkBlue: #25314D;
  --dark: #222;
  --light: #ddd;
}

::selection {
  background-color: var(--purple);
  color: var(--light);
}

html {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
}

body {
  position: relative
}
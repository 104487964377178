/* navbar animation */ 
.animated-icon {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.custom-btn {
    border: none !important;
    padding: 4px 8px 8px 8px;
    margin-right: 10px;
}

.custom-btn:hover {
    background-color: rgb(110, 110, 110);
    background-color: var(--grayBlue);
}
    
.animated-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.animated-icon span {
    background: #e3f2fd;
}
    
.animated-icon span:nth-child(1) {
    top: 0px;
}
    
.animated-icon span:nth-child(2), .animated-icon span:nth-child(3) {
    top: 10px;
}
    
.animated-icon span:nth-child(4) {
    top: 20px;
}
    
.animated-icon.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}
    
.animated-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
    
.animated-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
    
.animated-icon.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}

/* custom navbar styling */ 
.custom-nav {
    background-color: var(--dark);
    margin-bottom: 0 !important;
    position: fixed;
    width: 100%;
    top: 0;
    transition: top 0.3s;
    z-index: 9000;
}

.custom-nav .nav-name {
    margin-left: 25px;
}

.custom-nav li a {
    /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
    text-align: right;
    padding-right: 15px !important;
}

.custom-nav li a:hover {
    color: var(--grayBlue) !important;
    background-color:rgb(45, 45, 45);
}
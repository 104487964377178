.resume {
  background-color: white;
  min-height: 120vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.resume h1 {
  line-height: 1.5;
}

.check-out-my-resume {
  max-width: 80vw;
  padding-top: 2rem;
  margin: 2rem auto;
}

.technologies-title {
  margin: 2rem 0;
  text-align: center;
}

.resume a {
  text-decoration: none;
}

.resume a span {
  color: var(--blue);
  /* text-decoration: underline; */
  border: solid 4px var(--blue);
  padding: 5px;
}

.resume a span:hover {
  /* color: rgb(75, 0, 160); */
  color: white;
  background-color: var(--blue);
  transition-duration: 500ms;
  transition-property: background-color, color;
}

.tech-logo {
  margin: 1rem;
}

.technologies {
  margin: 0 auto 10rem;
}

.tech-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.tech-div p {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .resume h1 {
    font-size: 1.5rem;
    width: 90%;
    margin: 0.5rem auto;
  }

  .technologies-title {
    margin: 3rem auto 1rem !important;
  }

  /* .technologies {
    position: relative;
    left: 15px;
  } */
}

@media screen and (max-width: 350px) {
  .technologies {
    left: 0px;
  }
}

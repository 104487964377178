.contact {
  background-color: var(--dark);
  color: var(--darkBlue);
  /* min-height: 100vh; */
  border-radius: 0;
  border-bottom: 1px solid var(--dark);
  background-image: url("../../images/tech-background.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-size: 100vw; */
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.contact-me {
  color: var(--darkBlue) !important;
}

.contact-row {
  display: flex;
  flex-direction: column;
  padding-top: 175px;
}

.center-name-email {
  text-align: center;
  margin-top: 50px;
}

.contact-row h1 {
  font-size: 5rem;
  text-align: center;
  color: var(--purple);
}

.contact-row h2 {
  font-size: 3rem;
}

.contact-row h3 a {
  text-decoration: none;
  color: var(--darkBlue);
}

.contact-row .links {
  text-align: center;
  margin: 50px auto 0 auto;
  height: 50px;
  width: 260px;
  background-color: var(--dark);
  border-radius: 30px;
  padding: 10px;
}

.contact-row .links a {
  margin-left: 20px;
  margin-right: 20px;
}

.contact-row .links a img:hover {
  transform: scale(1.25, 1.25) !important;
  transition: 0.25s;
}

@media screen and (max-width: 600px) {
  .contact {
    background-attachment: initial;
    background-position: center;
  }

  .contact-row h1 {
    font-size: 3rem;
  }

  .center-name-email {
    padding: 1rem 0;
  }

  .contact-row h2 {
    font-size: 2rem;
  }

  .contact-row h3 {
    font-size: 1rem;
  }
}
